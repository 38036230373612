import React from "react";
import "../styles/information.css";
export const ReceivingWarehousesPage = () => {
  const book = [
    {
      name: "DIRECT EXPRESS INC / TRANS FREIGHT ENTERPRISES, INC",
      address: "34343 ZWISSIG WAY",
      city: "UNION CITY",
      state: "CA",
      zip: "94587",
      tel: "510 489 2740",
      ctc: "CHRISTIAN",
      email: "INFO@TRANSFREIGHT.US",
      additionalEmail: "CHRISTIAN@TRANSFREIGHT.U",
      hours: "M-F from 730am till 330pm",
      origin: "OAK",
      website: "WEB SITE TBA",
      username: "USER TBA",
      password: "PASSWORD TBA",
      additionalInfo: "N/A",
      country: "N/A",
    },
    {
      name: "DIRECT EXPRESS INC | V&S CARGO TEXAS INC HOU",
      address: "3703 MESA DRIVE",
      city: "HOUSTON",
      state: "TX",
      zip: "N/A",
      tel: "713 401 2633",
      ctc: "N/A",
      email: "txload@vsbrothers.com",
      additionalEmail: "N/A",
      hours: "N/A",
      origin: "HOU",
      website: "https://vsbrothers.com/login",
      username: "direct",
      password: "express111",
      additionalInfo: "N/A",
      country: "N/A",
    },
    {
      name: "SUN PACKING HOU",
      address: "10077 WALLISVILLE RD.",
      city: "HOUSTON",
      state: "TX",
      zip: "77013",
      tel: "832 730 3428",
      ctc: "PRINCESS",
      email: "princess@sunpacking.com",
      additionalEmail: "N/A",
      hours: "N/A",
      origin: "HOU",
      website: "WEB SITE TBA",
      username: "USER TBA",
      password: "PASSWORD TBA",
      additionalInfo: "N/A",
      country: "N/A",
    },
    {
      name: "DIRECT EXPRESSS INC / SAVANNAH EAST SAV",
      address: "323 COMMERCE DRIVE",
      city: "RINCON",
      state: "GA",
      zip: "31326",
      tel: "912 295 5747",
      ctc: "MASON",
      email: "m.reno@savannah-east.com",
      additionalEmail: "INFO@SAVANNAH-EAST.COM",
      hours: "N/A",
      origin: "SAV",
      website:
        "https://tracking.magaya.com/Default.aspx?orgname=36461#livetrack",
      username: "directex",
      password: "express310",
      additionalInfo: "N/A",
      country: "N/A",
    },
    {
      name: "DIRECT EXPRESS INC / MILLWRIGHT NY",
      address: "200 US 22",
      city: "HILLSIDE",
      state: "NJ",
      zip: "07205",
      tel: "908 862 0061",
      ctc: "NICK / NATASHA",
      email: "nick@shipmw.com",
      additionalEmail: "Nelson@shipmw.com",
      hours: "N/A",
      origin: "NY",
      website: "https://tracking.magaya.com/#livetrack",
      username: "directexp",
      password: "direct1",
      additionalInfo: "MANUALLY ENTER: 21529",
      country: "N/A",
    },
    {
      name: "DIRECT EXPRESSS INC / ALLCARGO EXPRESS",
      address: "200-2251 NO. 5 RD",
      city: "RICHMOND",
      state: "BC",
      zip: "V6X 2S8",
      tel: "604 606 0633",
      ctc: "RICK",
      email: "RICK@ALLCARGOEXPRESS.COM",
      additionalEmail: "N/A",
      hours: "N/A",
      origin: "VAN",
      website: "N/A",
      username: "N/A",
      password: "N/A",
      additionalInfo: "N/A",
      country: "CANADA",
    },
    {
      name: "DIRECT EXPRESSS INC / CARSHIP OVERSEAS",
      address: "1375 PITFIELD ST",
      city: "VILLE ST LAURENT MONTREAL",
      state: "QC",
      zip: "H4S 1G3",
      tel: "514 335 2414",
      ctc: "STEPHAN / ART",
      email: "STEPHAN@CARSHIP.CA",
      additionalEmail: "N/A",
      hours: "N/A",
      origin: "TOR",
      website: "N/A",
      username: "N/A",
      password: "N/A",
      additionalInfo: "N/A",
      country: "CANADA",
    },
  ];

  return (
    <div className="extraInformationContainer">
      <h2>Receiving Warehouses</h2>
      <div className="extraWiderInformationTable">
        <table>
          <thead>
            <tr>
              <th>Origin</th>
              <th className="wideColumn">Name</th>
              <th>Address</th>
              <th>City</th>
              <th>State</th>
              <th>Zip</th>
              <th>Tel</th>
              <th>CTC</th>
              <th>Email</th>
              <th>Additional Email</th>
              <th>Hours</th>
              <th>Info</th>
              <th>Website</th>
              <th>Username</th>
              <th>Password</th>
              <th>Additional Info</th>

              <th>Country</th>
            </tr>
          </thead>
          <tbody>
            {book.map((b, index) => (
              <tr key={index}>
                <td>{b.origin}</td>
                <td className="widerColumn">{b.name}</td>
                <td className="wideColumn">{b.address}</td>
                <td>{b.city}</td>
                <td>{b.state}</td>
                <td>{b.zip}</td>
                <td className="wideColumn">{b.tel}</td>
                <td>{b.ctc}</td>
                <td>{b.email}</td>
                <td>{b.additionalEmail}</td>
                <td>{b.hours}</td>
                <td>{b.info}</td>
                <td>
                  <a
                    href={b.website}
                    target="_blank"
                    className="websiteLink"
                    rel="noopener noreferrer"
                  >
                    Click here to open
                  </a>
                </td>
                <td>{b.username}</td>
                <td>{b.password}</td>
                <td>{b.additionalInfo}</td>
                <td>{b.country}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
