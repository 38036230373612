import React from "react";
import "../styles/information.css";
export const UsContainerDrayPage = () => {
  const book = [
    {
      info: "LOAD MATCH / FOR CONTAINER TRUCKING / DRAY ANY USA LOCATION",
      website: "https://loadmatch.com/",
      username: "rzavaleta",
      password: "shipdei",
    },
  ];
  return (
    <div className="extraInformationContainer">
      <h2>All USA Container Dray</h2>
      <div className="extraInformationTable">
        <table>
          <thead>
            <tr>
              <th className="wideColumn">Info</th>
              <th>Website</th>
              <th>Username</th>

              <th>Password</th>
            </tr>
          </thead>
          <tbody>
            {book.map((b, index) => (
              <tr key={index}>
                <td>{b.info}</td>
                {b.website && (
                  <td>
                    <a
                      href={b.website}
                      target="_blank"
                      className="websiteLink"
                      rel="noopener noreferrer"
                    >
                      Click here to open
                    </a>
                  </td>
                )}
                <td>{b.username}</td>

                <td>{b.password}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
