import React from "react";
import "../styles/information.css";
export const CentralDispatchInlandsPage = () => {
  const book = [
    {
      name: "SAFER SYSTEM",
      description: "TO VERIFY TRUCKERS INFORMATION AND INSURANCE",
      website: "https://safer.fmcsa.dot.gov/CompanySnapshot.aspx",
      user: "N/A",
      password: "N/A",
      contact: "N/A",
      email: "N/A",
      tel: "N/A",
      additionalInfo: "N/A",
    },
    {
      name: "centraldispatch.com",
      description: "TO TRANSPORT VEHICLES IN USA: AS CUSTOMER LISTING",
      website: "https://centraldispatch.com",
      user: "deilax",
      password: "quruq172",
      contact: "N/A",
      email: "N/A",
      tel: "N/A",
      additionalInfo: "ACT USER #",
    },

    {
      name: "centraldispatch.com",
      description: "POSTING CARS, AS BROKER PROVIDING SERVICES TO CUSTOMERS",
      website: "https://centraldispatch.com",
      user: "icars016",
      password: "olac1991",
      contact: "",
      email: "N/A",
      tel: "N/A",
      additionalInfo: "ACT USER #",
    },
    {
      name: "CENTRAL DISPATCH ONLINE RECORD KEEPING",
      description:
        "CENTRAL DISPATCH ONLINE RECORD KEEPING LINK BELOW: TRUCKING - Google Sheets link for system utilize on central dispatch, previous dispatches and records.",
      website:
        "https://docs.google.com/spreadsheets/d/1C7QS3yyOCi4EPE7X3goAYC1j8Aa7PQD8Y5mSL13F8wQ/edit?urp=gmail_link",
      user: "N/A",
      password: "N/A",
      contact: "N/A",
      email: "N/A",
      tel: "N/A",
      additionalInfo: "N/A",
    },

    {
      name: "CAMILLE TO DO DOMESTIC TRANSPORT",
      description: "VEHICLE DISPATCH ORDER STATUS - CAMILLE - Google Sheets",
      website:
        "https://docs.google.com/spreadsheets/d/1xx6y5NJyKMMFKznh1Rs0GuBcaoubRu0LaTktSP6n_Ww/edit?urp=gmail_link",
      user: "N/A",
      password: "N/A",
      contact: "CAMILLE",
      email: "info@additive-usa.com",
      tel: "424 303 0147",
      additionalInfo: "N/A",
    },
    {
      name: "INLAND USA",
      description: "OVERSIZE CARRIER LOW BOY / SLIDING LOW BOY 40 FOOTER LONG",
      website: "N/A",
      user: "N/A",
      password: "N/A",
      contact: "JAMES",
      email: "N/A",
      tel: "818 216 6033",
      additionalInfo: "N/A",
    },
    {
      name: "CANADA EAST COAST – TORONTO",
      description: "ANY LCL LOOSE WE CAN TRY KARIM / HOT WHEELS",
      website: "N/A",
      user: "N/A",
      password: "N/A",
      contact: "KARIM",
      email: "hotwheelstransportation@gmail.com",
      tel: "514 887 5354",
      additionalInfo: "N/A",
    },
    {
      name: "CANADA TO USA TFX",
      description:
        "Vehicle Shipping Across Canada & to the US | TFX International",
      website: "https://www.tfxinternational.com",
      user: "N/A",
      password: "N/A",
      contact:
        "adam@tfxinternational.com, caitlin@tfxinternational.com, kendra@tfxinternational.com",
      email: "N/A",
      tel: "888 415 3158, 416 243 8531 xtn 8",
      additionalInfo: "N/A",
    },
  ];

  return (
    <div className="extraInformationContainer">
      <h2>Central Dispatch Inlands</h2>
      <div className="extraWiderInformationTable">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Website</th>
              <th>Username</th>
              <th>Password</th>
              <th>Contact</th>
              <th>Email</th>
              <th>Tel</th>
              <th>Additional Info</th>
            </tr>
          </thead>
          <tbody>
            {book.map((b, index) => (
              <tr key={index}>
                <td>{b.name}</td>
                <td className="wideColumn">{b.description}</td>
                <td>
                  <a
                    href={b.website}
                    target="_blank"
                    className="websiteLink"
                    rel="noopener noreferrer"
                  >
                    Click here to open
                  </a>
                </td>
                <td>{b.user}</td>
                <td>{b.password}</td>
                <td>{b.contact}</td>
                <td>{b.email}</td>
                <td className="wideColumn">{b.tel}</td>
                <td>{b.additionalInfo}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
